<template>
  <div class="bg-brand-gray-100 min-h-full">
    <PageHeader>
      <div class="flex items-center">
        <router-link
          to="profile"
          class="px-2 -ml-2 py-1 inline-block focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </router-link>
      </div>

      <h1 class="font-medium">Absences</h1>

      <!-- <div class="flex items-center justify-end">
        <router-link
          to="projects"
          class="opacity-75 text-right focus:outline-none py-1 px-2 -mr-2"
        >
          Projects
        </router-link>
      </div> -->
    </PageHeader>

    <main class="mt-4">
      <div class="text-center px-6">
        <h2 class="text-xl">Request a sick day</h2>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form
            action=""
            class="mt-8"
            @submit.prevent="handleSubmit(submit)"
            novalidate
          >
            <ValidationProvider
              name="Date"
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-date-picker
                v-model="inputs.dates"
                mode="multiple"
                :disabled-dates="{ weekdays: [1, 7] }"
                :locale="locale"
                :max-date="new Date()"
              >
                <input
                  type="text"
                  name="Date"
                  aria-label="Date"
                  :value="formatedInputDates"
                  placeholder="Select dates"
                  class="block w-full px-0 bg-transparent border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  readonly
                />
              </v-date-picker>

              <div
                class="h-6 text-sm flex items-center text-red-500 font-light"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
              <textarea
                name="Comment"
                id="comment"
                aria-label="Comment"
                placeholder="Add more information here"
                class="mt-2 block w-full px-0 bg-transparent border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                required
                v-model="inputs.comments"
              ></textarea>

              <div
                class="h-6 text-sm flex items-center text-red-500 font-light"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <button
              type="submit"
              class="mt-6 w-56 py-3 border border-black rounded-full outline-none"
            >
              Send
              <span v-if="inputs.dates.length === 1">1 day</span>
              <span v-else-if="inputs.dates.length > 1"
                >{{ inputs.dates.length }} days</span
              >
            </button>
          </form>
        </ValidationObserver>
      </div>
      <div class="mt-10">
        <h2 class="text-center text-xl">Absences sent lately</h2>

        <div class="mt-3 font-light">
          <ul
            v-if="absences.length"
            class="divide-y border-t border-b border-brand-gray-200 divide-brand-gray-200"
          >
            <li
              v-for="absence in absences"
              :key="absence.id"
              class="px-4 py-2 flex items-center justify-between bg-white h-16 max-w-full"
            >
              <div class="flex flex-1 items-center overflow-hidden">
                <span class="flex-shrink-0 min-w-date">{{
                  format(parseISO(absence.date), "do MMM")
                }}</span>
                <span
                  class="ml-1 opacity-50 truncate text-sm"
                  v-if="absence.comments"
                >
                  {{ absence.comments }}
                </span>
              </div>
            </li>
          </ul>
          <span v-else class="px-4">
            Your defenses are like the A-Team. You haven’t been ill so far!
          </span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  format,
  formatISO,
  parseISO,
  startOfDay,
  startOfMonth,
  sub,
} from "date-fns";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { messages } from "vee-validate/dist/locale/en.json";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapState } from "vuex";

import PageHeader from "@/components/PageHeader";
import absenceService from "@/services/absenceService.js";

setInteractionMode("eager");

extend("required", { ...required, message: messages["required"] });

export default {
  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      formatISO,
      format,
      parseISO,
      locale: {
        id: "en",
        firstDayOfWeek: 2,
      },
      inputs: {
        dates: [startOfDay(new Date())],
        comments: "",
      },
    };
  },
  async created() {
    if (Object.keys(this.currentStaff).length === 0) {
      await this.getCurrentStaff();
    }

    const minDate = sub(startOfMonth(new Date()), {
      months: 12,
    });

    const startDate = formatISO(minDate, { representation: "date" });
    const endDate = formatISO(new Date(), { representation: "date" });
    await this.getAbsencesbyParams({
      staff: this.currentStaff.id,
      dateRange_after: startDate,
      dateRange_before: endDate,
    });
  },
  computed: {
    ...mapState("absences", ["absences"]),
    ...mapState("staff", ["currentStaff"]),
    formatedInputDates() {
      return this.inputs.dates.map((date) => {
        return " " + format(date, "do MMM");
      });
    },
  },
  methods: {
    ...mapActions("absences", ["getAbsencesbyParams", "addAbsence"]),
    ...mapActions("staff", ["getCurrentStaff"]),
    submit() {
      if (!this.inputs.dates.length || !this.inputs.comments.length) return;

      this.inputs.dates.forEach((date) => {
        this.addAbsence({
          staff: this.currentStaff.url,
          date: formatISO(date, { representation: "date" }),
          comments: this.inputs.comments,
        });
      });

      this.inputs.dates = [startOfDay(new Date())];
      this.inputs.comments = "";

      this.$nextTick(() => {
        this.$refs.form.reset();
        absenceService.sendAbsenceEmail();
      });
    },
  },
};
</script>

<style>
.tabular-num {
  font-feature-settings: "tnum";
  letter-spacing: -0.7px;
}

.min-w-date {
  min-width: 4rem;
}
</style>
